import React from "react"
import newCourseService from "../services/course_service";
import AuthenticatedFullscreenLayout from "../components/layouts/authenticatedFullscreenLayout";
import Step from "../components/course-browser/step";
import NavbarInContext from "../components/navBarInContext";
const JessicasPage = () => (
    <AuthenticatedFullscreenLayout title="Jessica's Room" section={newCourseService.sections.jessica}>
        <Step/>
    </AuthenticatedFullscreenLayout>
)

export default JessicasPage
