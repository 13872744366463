import React from "react"
import { redirectTo } from "@reach/router"
import userService from "../../services/user";
import {Col, Container, Row} from "react-bootstrap";
import { navigate } from "gatsby"
import CourseHOC from "../../hoc/courseHoc";
import Section from "../../models/course/section";
import CustomNavbar from "../navBar";
import NavbarInContext from "../navBarInContext";
import MainMenu from "../mainMenu";

type AuthenticatedLayoutProps = {
    children: any,
    title: string,
    section?: Section
}
class AuthenticatedFullscreenLayout extends React.Component<AuthenticatedLayoutProps> {

    readonly children: any;
    readonly title: string;
    isLoggedIn: boolean = false;
    section: Section | undefined;
    constructor(props: AuthenticatedLayoutProps)
    {
        super(props);
        this.children = props.children;
        this.title = props.title;
        this.section = props.section;
    }

    componentDidMount() {
        if (!userService.isLoggedIn()) {
            navigate("/login")
        }
    }

    containerStyle = {
        "display":"flex",
        "flexGrow":"1",
    };


    render() {
        
        return (
            <CourseHOC section={this.section}>
                <div className={"bgImage"}></div>
                <div className={"d-flex justify-content-start"} style={{"height":"100vh"}}>
                    <MainMenu/>
                    <div className="px-0 main p-0" style={this.containerStyle}>
                        <div style={{"height":"100vh", "width":"100%" }}>
                            <main className={"w-100 d-flex justify-content-center"} style={{"flexDirection":"column","overflow":"hidden","height":"100vh"}}>{this.children}</main>
                        </div>
                    </div>
                </div>
            </CourseHOC>
        )
    }
}

export default AuthenticatedFullscreenLayout